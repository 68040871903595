<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
        prominent
      >
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
      </v-toolbar>
      <v-flex
        xs6
      >
        <v-text-field
          v-model="msisdn"
          label="Enter MSISDN"
          outline
        />
      </v-flex>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Reject MNP
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Reject MNP on {{ msisdn }}</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeRejectMnp"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createPostParams } from '@/rest'

const pageTitle = 'Reject MNP'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data: () => ({
    title: pageTitle,
    alert: false,
    alertMessage: null,
    msisdn: null,
    loading: false,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    breadcrumbs: [
      {
        text: 'Mnp', disabled: false, to: '/support/mnp',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
  }),

  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the MNP status in SCRMS is relevant.</div>'
      }
    },
  },

  methods: {
    async executeRejectMnp () {
      const params = createPostParams({
        action: 'rejectMnp',
        payload: {
          msisdn: this.msisdn,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to reject an MNP was successful.'
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },

    close () {
      this.dialog = false
    },
  },
}
</script>
